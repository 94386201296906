<template>
    <div class="dashboard-container" :style="{width:width,height:'400px',overflow:'scroll',float:'right'}">
        <el-tabs v-model="activeName" @tab-click="handleClick" stretch>
            <el-tab-pane label="设备实时数据" name="first">
                <el-table :data="pvDeviceData" border fit highlight-current-row style="width: 100%">
                    <el-table-column prop="name" label="名称">
                    </el-table-column>
                    <el-table-column prop="data" label="属性">
                        <template slot-scope="scope">
                            <div v-html="scope.row.data"></div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="设备历史数据" name="second">
                <div v-if="deviceName=='智能检测设备'" :style="{width: '100%',height: '100%'}">
                    <device-chart :width="width"  :chart-data="lineChartData3"/>
                </div>
                <div v-if="deviceName=='接地电阻测试仪'">
                    <device-chart-2 :width="width" :chart-data="lineChartData4"/>
                </div>
                <div style="text-align:center;" v-if=" deviceName=='总线模式'||deviceName=='摄像头' ">暂无历史图表数据</div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>

    import {getDeviceDatas, getDeviceDatasHis} from "../api/devicenews";

    // import store from "@/store";

    import DeviceChart from './DeviceChart.vue';
    //
    import DeviceChart2 from './DeviceChart2.vue';

    export default {
        name: 'DisPlayData',
        components: {DeviceChart,DeviceChart2},
        props: ['deviceCode', 'deviceName'],
        data() {
            return {
                activeName: 'first',
                lineChartData3: null,
                timer: null,
                pvDeviceData: null,
                lineChartData4: null,
                deviceCode: "",
                deviceName: "",
                width:'350px'
            }
        },
        mounted() {

        },
        created() {
            let os = this.getDeviceType();
            //console.log('os', os)
            if (os.isAndroid || os.isPhone) {
                // 手机
                //console.log("手机")
                this.width = '95vw'
            } else if (os.isTablet) {
                //console.log("平板")
                // 平板
            } else if (os.isPc) {
                // 电脑
                //console.log("电脑")
            }

            let geturl = window.location.href;
            // http://localhost:8081/#/pages/index/index?qycode=1001&qyname=%E4%BC%81%E4%B8%9A%E5%BF%99
            let getqyinfo = geturl.split('?')[1]
            let getqys = getqyinfo.split('&')
            let obj = {}  //创建空对象，接收截取的参数
            for (let i = 0; i < getqys.length; i++) {
                // //console.log(i)
                let item = getqys[i].split('=')
                let key = item[0]
                let value = item[1]
                obj[key] = value
            }
            // //console.log(obj);

            this.deviceCode = obj.code
            if (obj.deviceName === "1"){
                this.deviceName = "接地电阻测试仪"
            }else if(obj.deviceName === "2"){
                this.deviceName = "智能检测设备"
            }
            else if(obj.deviceName === "3"){
                this.deviceName = "智能检测单元(PC模式)"
            }

            this.showDetails()
        },
        methods: {
            getDeviceType () {
                var ua = navigator.userAgent,
                    isWindowsPhone = /(?:Windows Phone)/.test(ua),
                    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
                    isAndroid = /(?:Android)/.test(ua),
                    isFireFox = /(?:Firefox)/.test(ua),
                    isChrome = /(?:Chrome|CriOS)/.test(ua),
                    isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox &&
                        /(?:Tablet)/.test(ua)),
                    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
                    isPc = !isPhone && !isAndroid && !isSymbian;
                return {
                    isTablet: isTablet,
                    isPhone: isPhone,
                    isAndroid: isAndroid,
                    isPc: isPc
                };
            },
            showDevices() {
                // this.pvDeviceData = []

                let param = {
                    code: this.deviceCode
                }
                //  //console.log(param)

                getDeviceDatas(param).then(res => {
                    //  //console.log("==========================")
                    //  //console.log(res)

                    this.deviceName = res.data.data.kadThunderDeviceType.typeName;

                    if (this.deviceName == "智能检测设备") {
                        this.deviceData = JSON.parse(res.data.data.data)
                        this.pvDeviceData = [
                            {
                                name: '更新日期',
                                data: this.formatDate(res.data.data.updateTime).slice(0,10)+"<br>"+this.formatDate(res.data.data.updateTime).slice(10,20)
                            },
                            {
                                name: '断路器-1 状态',
                                data: this.deviceData.breaker1status
                            },
                            {
                                name: '断路器-2 状态',
                                data: this.deviceData.breaker2status
                            },
                            {
                                name: '防雷模块-1 状态',
                                data: this.deviceData.lightning_protection_module1_status
                            },
                            {
                                name: '防雷模块-2 状态',
                                data: this.deviceData.lightning_protection_module2_status
                            },
                            {
                                name: '雷击极性',
                                data: this.deviceData.lightning_polarity
                            },
                            {
                                name: '雷击电流',
                                data: this.deviceData.lightning_current
                            },
                            {
                                name: '工频电流',
                                data: this.deviceData.work_current
                            },
                            {
                                name: '温度',
                                data: this.deviceData.temp / 100 + '℃'
                            },
                            {
                                name: '温度极性',
                                data: this.deviceData.temp_polarity
                            },

                            {
                                name: '漏电流-1',
                                data: this.deviceData.leakage_current1
                            },
                            {
                                name: '漏电流-2',
                                data: this.deviceData.leakage_current2
                            }

                        ]
                    } else if (this.deviceName.includes("接地电阻测试仪")) {
                        this.deviceData = JSON.parse(res.data.data.data)
                        this.pvDeviceData = [
                            {
                                name: '更新日期',
                                data: this.formatDate(res.data.data.updateTime).slice(0,10)+"<br>"+this.formatDate(res.data.data.updateTime).slice(10,20)
                            },
                            {
                                name: '电阻值',
                                data: this.deviceData.r_value + "Ω"
                            }
                        ]

                    } else if (this.deviceName == '智能检测单元(PC模式)') {

                        //  //console.log("-----")
                        //  //console.log(res.data)
                        this.deviceData = JSON.parse(res.data.data.data)

                        let str, str2, str3, str4
                        if (this.deviceData.switch1 == -1) {
                            str = '未启用'
                        } else if (this.deviceData.switch1 == 0) {
                            str = '连接'
                        } else if (this.deviceData.switch1 == 1) {
                            str = '断开'
                        }
                        if (this.deviceData.switch2 == -1) {
                            str2 = '未启用'
                        } else if (this.deviceData.switch2 == 0) {
                            str2 = '连接'
                        } else if (this.deviceData.switch2 == 1) {
                            str2 = '断开'
                        }
                        if (this.deviceData.switch3 == -1) {
                            str3 = '未启用'
                        } else if (this.deviceData.switch3 == 0) {
                            str3 = '连接'
                        } else if (this.deviceData.switch3 == 1) {
                            str3 = '断开'
                        }
                        if (this.deviceData.switch4 == -1) {
                            str4 = '未启用'
                        } else if (this.deviceData.switch4 == 0) {
                            str4 = '连接'
                        } else if (this.deviceData.switch4 == 1) {
                            str4 = '断开'
                        }

                        this.pvDeviceData = [
                            {
                                name: '更新日期',
                                data: this.formatDate(res.data.data.updateTime).slice(0,10)+"<br>"+this.formatDate(res.data.data.updateTime).slice(10,20)
                            },
                            {
                                name: '模拟量数目',
                                data: this.deviceData.ANOlNUM
                            },
                            {
                                name: '开关量数目',
                                data: this.deviceData.switchNum
                            }, {
                                name: '雷击极性',
                                data: this.deviceData.lightpolarity
                            }, {
                                name: '雷击电流',
                                data: this.deviceData.lightCurrent
                            }, {
                                name: '温度极性',
                                data: this.deviceData.tempPolarity
                            },
                            {
                                name: '温度',
                                data: this.deviceData.temp / 100 + '℃'
                            },

                            {
                                name: 'SPD寿命',
                                data: this.deviceData.spdLife
                            },
                            {
                                name: '雷电流次数',
                                data: this.deviceData.lightCurrentNum
                            },

                            {
                                name: '雷电流历史次数',
                                data: this.deviceData.lightCurrentHistoryNum
                            },
                            {
                                name: '防雷模块1',
                                data: str
                            },
                            {
                                name: '防雷模块2',
                                data: str2
                            }, {
                                name: '断路器1',
                                data: str3
                            }, {
                                name: '断路器2',
                                data: str4
                            },

                        ]
                    }
                })
            },

            //显示设备 实时信息
            showDetails() {
                this.showDeviceHisData()
                this.showDevices()
                this.dialogDeviceData = true
                this.timer = setInterval(() => {
                    //需要定时执行的代码
                    //  //console.log("开启定时器")
                    this.showDevices()
                }, 5000)
                // clearInterval(timer) 清除定时器
            },

            //日期格式化
            formatDate(oldDate) {
                // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
                function add0(num) {
                    return num < 10 ? '0' + num : num
                } // 个位数的值在前面补0
                const date = new Date(oldDate)
                const Y = date.getFullYear()
                const M = date.getMonth() + 1
                const D = date.getDate()
                const h = date.getHours()
                const m = date.getMinutes()
                const s = date.getSeconds()
                const dateString = Y + '-' + add0(M) + '-' + add0(D) + '  ' + add0(h) + ':' + add0(m) + ':' + add0(s)
                return dateString
                // 方式2 转换为'yyyy/MM/dd HH:mm:ss'
                // return new Date(oldDate).toLocaleString()
            },

            /**
             * 显示历史数据
             */
            showDeviceHisData() {
                let param = {
                    code: this.deviceCode
                }
                //console.log(this.deviceName)
                getDeviceDatasHis(param).then(res => {
                    if (this.deviceName == '智能检测设备') {
                        let datas = {
                            lightningCurrent: [],
                            workCurrent: [],
                            temp: [],
                            dataTime: []
                        }
                        if (res.data.data != null) {
                            res.data.data.forEach((item, index) => {
                                    let psData = JSON.parse(item.data)
                                    datas.lightningCurrent.push(psData.lightning_current)
                                    datas.workCurrent.push(psData.work_current)
                                    datas.temp.push(psData.temp / 100)
                                    datas.dataTime.push(this.formatDate(item.createTime))
                            });
                        }
                        this.lineChartData3 = datas
                    } else if (this.deviceName == '接地电阻测试仪') {
                        let datas = {
                            values: [],
                            dataTime: [],
                            max:"",
                            min:""
                        }
                        let preData = 0;
                        if (res.data.data != null) {
                            res.data.data.forEach((item, index) => {
                                    let psData = JSON.parse(item.data)
                                    // if (preData === 0 && psData.r_value === "0.0"){
                                    //     return;
                                    // }
                                    // if (psData.r_value === "0.0"){
                                    //     // //console.log("----------------------")
                                    //     psData.r_value = preData;
                                    // }
                                    datas.values.push(psData.r_value)
                                    datas.dataTime.push(this.formatDate(item.createTime))
                                    // preData = psData.r_value;
                                    // //console.log(preData)
                            });
                        }

                        // datas.values.sort(function(a,b){
                        //     return b-a;
                        // })

                        // let max = datas.values[0];
                        // let min = datas.values[datas.values.length-1];
                        // datas.max = (parseFloat(max) + 0.5).toFixed(1);
                        // datas.min = (min - 0.5).toFixed(1);

                        this.lineChartData4 = datas;
                    }
                    //  //console.log(datas)
                })
            },
            closeDialog() {
                //清除定时器
                //  //console.log("关闭定时器")
                clearInterval(this.timer)
            },
            handleClick(tab, event) {
                //  //console.log(tab, event);
            }
        },
        destroyed() {
            //  //console.log("9999999999999999999999")
            clearInterval(this.timer)
        }
    }
</script>

<style scoped>
    #container {
        width: 100%;
        height: 500px;
    }
    .dashboard-container::-webkit-scrollbar {display:none}

    ::v-deep .el-tabs__item{
        color: white;
    }

    ::v-deep .cell{
        font-size: 13px!important;
    }
</style>






