import request from "../utils/request"
import qs from 'qs'

//获取 设备 实时数据
export function getDeviceDatas(data) {
    return request({
        url: '/auth/device/getDeviceData',
        method: 'post',
        transformRequest: [function (data) {
            // 对 data 进行任意转换处理
                return qs.stringify(data)
            }],
        data
      })
}


//获取设备历史数据
export function getDeviceDatasHis(data) {
    return request({
        url: '/auth/device/getDeviceHistoryDatas',
        method: 'post',
        transformRequest: [function (data) {
            // 对 data 进行任意转换处理
                return qs.stringify(data)
            }],
        data
      })
}
