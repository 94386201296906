<template>
    <div ref="chart" :class="className" :style="{height:height,width:width}">

    </div>
</template>

<script>
    import * as echarts from 'echarts'

    require('echarts/theme/macarons') // echarts theme
    import resize from '../mixins/resize'

    export default {
        mixins: [resize],
        props: {
            className: {
                type: String,
                default: 'chart'
            },
            width: {
                type: String,
                default: '350px'
            },
            height: {
                type: String,
                default: '330px'
            },
            autoResize: {
                type: Boolean,
                default: true
            },
            chartData: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                chart: null
            }
        },
        watch: {
            chartData: {
                deep: true,
                handler(val) {
                    console.log(val);
                    this.setOptions(val)
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.initChart()
            })
        },
        methods: {
            initChart() {
                this.chart = echarts.init(this.$refs.chart)
                this.setOptions(this.chartData)
            },

            setOptions({values, dataTime, max, min} = {}) {
                console.log(values, dataTime, min, max)
                this.chart.setOption({
                    title: {
                        text: "设备近24小时历史数据",
                        subtext: "",
                        left: "center",
                        textStyle: {
                            color: "white", //标签文字颜色改为白色
                        },
                    },
                    xAxis: {
                        data: dataTime,
                        boundaryGap: false,
                        axisTick: {
                            show: false
                        },
                        axisLabel:{
                            textStyle:{//改变xy轴上文字的颜色
                                color:"#FFF"
                            }
                        },
                    },
                    grid: {
                        left: 10,
                        right: 20,
                        bottom: 20,
                        top: 30,
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        },
                        padding: [5, 10]
                    },
                    yAxis: {
                        axisTick: {
                            show: false
                        },
                        max: max,
                        min: min,
                        axisLabel:{
                            textStyle:{//改变xy轴上文字的颜色
                                color:"#FFF"
                            }
                        },
                    },
                    legend: {
                        orient: 'horizontal',
                        data: ['电阻值'],
                        x: 'center',
                        y: 'bottom',
                        textStyle: { //图例文字的样式
                            color: '#fff',
                            fontSize:10
                        },
                    },
                    series: [{
                        name: '电阻值', itemStyle: {
                            normal: {
                                color: '#FF005A',
                                lineStyle: {
                                    color: '#FF005A',
                                    width: 2
                                }
                            }
                        },
                        smooth: false,
                        type: 'line',
                        data: values,
                        animationDuration: 2800,
                        animationEasing: 'cubicInOut'
                    }
                    ]
                })
            }
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },
    }
</script>
