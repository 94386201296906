import Vue from "vue"
import axios from 'axios'
// create an axios instance
const service = axios.create({
  baseURL: "https://jiafei.imdo.co/light/", // url = base url + request url
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 500000 // request timeout
})

// post请求头

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // conole.log(response.data)
    return response
  },
   error => {
      let that =this
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

Vue.prototype.$axios = axios
export default service
